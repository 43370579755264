import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import Map from "../components/ContactsPage/Map"
import Form from "../components/ContactsPage/Form"

const ContactsPage = () => {
  return (
    <>
      <GatsbySeo title="Contact Us" titleTemplate="%s | BESHAUS" />
      <Map />
      <Form />
    </>
  )
}

export default ContactsPage
