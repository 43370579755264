import React from "react"

const Map = () => {
  return (
    <section className="relative">
      <div className="grid grid-cols-1 grid-rows-1">
        <div className="w-full h-full min-h-[450px]">
          <iframe
            title="beshaus location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.613105250955!2d105.86897061493302!3d21.048161185987915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab94e2bc30ad%3A0x4c7d1f9cc28c54fa!2sV%C4%83n%20ph%C3%B2ng%20ki%E1%BA%BFn%20tr%C3%BAc%20Beshaus%20-%20Architecture%20Studio!5e0!3m2!1sen!2s!4v1628704228491!5m2!1sen!2s"
            width="100%"
            height="100%"
            style={{
              border: 0,
              filter:
                "brightness(100%) contrast(100%) saturate(0%) blur(0) hue-rotate(0deg)",
            }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
  )
}

export default Map
