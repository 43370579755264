import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Heading from "../Heading"

const query = graphql`
  {
    contentfulAboutMe {
      location
      gmail
      tel
    }
  }
`

const Form = () => {
  const data = useStaticQuery(query)
  const partialAbout = data?.contentfulAboutMe

  return (
    <section className="bg-[#0A0A0A]">
      <div className="grid grid-cols-1 xl:grid-cols-2 items-center">
        <div className="px-6 md:px-20 py-20">
          <h2 className="text-4xl">Get in Touch</h2>
          <p className="text-sm mt-3 text-beshaus-body-text">
            Your email address will not be published. Required fields are marked
            *
          </p>
          <form
            onSubmit={e => {
              e.preventDefault()
              alert("Sent")
            }}
            className="mt-6"
          >
            <div className="space-y-5">
              <input
                type="text"
                required
                className="block w-full p-3 border-0 border-b border-white border-opacity-20 focus:ring-0 focus:border-white focus:border-opacity-20 bg-transparent text-beshaus-body-text placeholder-beshaus-body-text"
                placeholder="your name *"
              />
              <input
                type="email"
                required
                className="block w-full p-3 border-0 border-b border-white border-opacity-20 focus:ring-0 focus:border-white focus:border-opacity-20 bg-transparent text-beshaus-body-text placeholder-beshaus-body-text"
                placeholder="your email *"
              />
              <textarea
                type="text"
                required
                rows="5"
                className="block w-full p-3 border-0 border-b border-white border-opacity-20 focus:ring-0 focus:border-white focus:border-opacity-20 bg-transparent text-beshaus-body-text placeholder-beshaus-body-text"
                placeholder="message..."
              />
            </div>
            <button
              type="submit"
              className="mt-8 px-9 py-4 bg-[#1A1A1A] text-white uppercase text-sm font-semibold transition-colors duration-300 ease-linear hover:bg-white hover:text-[#1A1A1A]"
            >
              send message
            </button>
          </form>
        </div>
        <div className="relative w-full h-full">
          <div className="absolute top-0 left-0 w-full h-full">
            <StaticImage
              src="../../assets/images/contact-box-background.jpg"
              className="w-full h-full"
              quality="100"
              placeholder="blurred"
              alt="contact box background"
            />
          </div>
          <div className="relative px-6 md:px-20 py-20">
            <Heading
              preHeading="our contact details"
              heading="Let's Start a Project"
              noBorder
            />
            <p className="mt-8 leading-loose text-beshaus-body-text font-raleway">
              Give us a call or drop by anytime, we endeavour to answer all
              enquiries within 24 hours on business days. We will be happy to
              answer your questions.
            </p>
            <div className="mt-8 space-y-6">
              <div className="flex">
                <i
                  className={`flaticon-place before:text-[30px] mr-6 self-center`}
                ></i>
                <div>
                  <p className="uppercase font-semibold">our address:</p>
                  <p className="mt-2 font-raleway font-light">
                    {partialAbout.location}
                  </p>
                </div>
              </div>
              <div className="flex">
                <i
                  className={`flaticon-mail before:text-[30px] mr-6 self-center`}
                ></i>
                <div>
                  <p className="uppercase font-semibold">our mailbox:</p>
                  <p className="mt-2 font-raleway font-light">
                    {partialAbout.gmail}
                  </p>
                </div>
              </div>
              <div className="flex">
                <i
                  className={`flaticon-telephone before:text-[30px] mr-6 self-center`}
                ></i>
                <div>
                  <p className="uppercase font-semibold">our phone:</p>
                  <p className="mt-2 font-raleway font-light">
                    {partialAbout.tel}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Form
